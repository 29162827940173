<template>
  <div class="adviceBox">
    <div class="display_flex top">
      <!-- 顶部左侧 -->
      <div class="search-top">
        <el-form
          :inline="true"
          :model="formData"
          ref="searchForm"
          class="demo-form-inline"
        >
          <el-form-item>
            <el-input
              class="w-208"
              v-model="formData.searchs"
              clearable
              @keydown.enter="search"
              placeholder="请输入搜索信息"
            >
              <template #suffix>
                <div @click="search" class="searchcss">
                  <i class="fa fa-search"></i>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="formData.status"
              placeholder="请选择"
              @change="selectchan"
            >
              <el-option label="在用" value="1" />
              <el-option label="停用" value="0" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              @change="type"
              placeholder="选择种类"
              v-model="formData.dict_type"
            >
              <el-option
                v-show="activeName == 1"
                v-for="item in dictList[160000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
              <!-- 耗材 -->
              <el-option
                v-show="activeName == 2"
                v-for="item in dictList[164000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
              <!-- 治疗项 -->
              <el-option
                v-show="activeName == 3"
                v-for="item in dictList[161000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 30px">
            <div class="display_flex" style="color: #3166ae">
              <el-tooltip placement="bottom">
                <template #content>
                  <div style="width: 200px">
                    判重规则：药品目录按名称+规格+厂家+售价为条件判断是否重复，药品名称、规格、厂家字段不提供修改，满足下列两种条件可修改售价，1：该药品未入过库
                    2：该药品入库了但库存数为0（退货或出库可使库存清零）
                  </div>
                </template>
                <div class="faicon">
                  <i class="fa fa-question-circle-o icou1"></i>
                </div>
              </el-tooltip>
              <div>判重规则</div>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!-- 顶部右侧 -->
      <div class="item">
        <el-button @click="add" class="color-green" type="primary">
          <i class="fa fa-plus-circle"></i>新增
        </el-button>
        <!-- <el-button class="color-vice" type="primary">
          <i class="fa fa-arrow-up"></i>导入
        </el-button>
        <el-button class="color-vice" type="primary">
          <i class="fa fa-arrow-down"></i>导出
        </el-button>-->
      </div>
    </div>

    <!-- 药品标签筛选 -->
    <div class="tabclis" v-if="activeName === '1'">
      <!--左侧 -->
      <div class="display_flex" style="color: #3166ae; margin: 0 10px">
        <div class="faicon">
          <i class="fa fa-question-circle-o icou1"></i>
        </div>
        <div style="margin-top: 4px">标签筛选：</div>
      </div>
      <!-- 右侧 -->
      <div class="tabclis_right" v-if="activeName === '1'">
        <div class="parient-tag">
          <el-tag
            :class="item.active ? 'active' : ''"
            v-for="item in listop"
            :key="item.id"
            @click="tagBut(item)"
            >{{ item.name }}</el-tag
          >
        </div>
      </div>
    </div>
    <!-- 治疗项标签筛选 -->
    <div class="tabclis" v-if="activeName === '3'">
      <!--左侧 -->
      <div class="display_flex" style="color: #3166ae; margin: 0 10px">
        <div class="faicon">
          <i class="fa fa-question-circle-o icou1"></i>
        </div>
        <div style="margin-top: 4px">标签筛选：</div>
      </div>
      <!-- 右侧 -->
      <div class="tabclis_right">
        <div class="parient-tag">
          <el-tag
            :class="item.active ? 'active' : ''"
            v-for="item in doclist"
            :key="item.id"
            @click="tagButs(item)"
            >{{ item.name }}</el-tag
          >
        </div>
      </div>
    </div>
    <!-- tabs标签页 -->
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="药品目录" name="1">
          <div class="tables">
            <el-table
              v-loading="dataListLoading"
              :data="tableData"
              stripe
              border
              max-height="500px"
            >
              <el-table-column
                width="120"
                prop="dict_type_name"
                fixed
                label="药品种类"
              />
              <el-table-column width="150" fixed prop="name" label="药品名称" />
              <el-table-column width="120" prop="medi_spec" label="规格" />
              <el-table-column
                width="90"
                prop="dict_first_unit_name"
                label="常用单位"
              />
              <el-table-column width="90" prop="dosage" label="单次剂量" />
              <el-table-column
                width="90"
                prop="dict_base_unit_name"
                label="计算单位"
              />
              <el-table-column
                width="110"
                prop="dict_use_way_name"
                label="用药途径"
              />
              <el-table-column
                width="120"
                prop="dict_frequency_name"
                label="用药频次"
              />
              <el-table-column width="180" label="标签">
                <template #default="scope">
                  <div class="parient-tag">
                    <el-tag v-for="item in scope.row.tag" :key="item.id">
                      {{ item.dict_tag_name }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="90" prop="buy_price" label="进价" />
              <el-table-column width="90" prop="sell_price" label="售价" />
              <!-- <el-table-column
                width="100"
                prop="is_early_warning"
                label="库存预警"
              />
              <el-table-column
                width="100"
                prop="effective_warning"
                label="有效预警"
              /> -->
              <el-table-column
                width="260"
                prop="company"
                label="生产厂家"
              />
              <el-table-column width="90" prop="is_enable" label="是否启用">
                <template #default="scope">
                  <el-tag v-if="scope.row.is_enable == 1">是</el-tag>
                  <el-tag v-else type="danger">否</el-tag>
                </template>
              </el-table-column>
              <el-table-column width="160" prop="remark" label="备注" />
              <el-table-column fixed="right" label="操作" width="150">
                <template #default="scope">
                  <el-popconfirm
                    title="是否确定停用?"
                    @confirm="deatilsClick(scope.row)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts stop">停</span>
                      </el-button>
                    </template>
                  </el-popconfirm>

                  <el-button
                    type="text"
                    size="small"
                    @click="modParient(scope.row)"
                  >
                    <span class="txfonts modify">改</span>
                  </el-button>
                  <el-popconfirm
                    title="是否确定删除?"
                    @confirm="delParient(scope.row)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts del">删</span>
                      </el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination-box">
            <el-pagination
              v-model:currentPage="pageData.currentPage"
              v-model:page-size="pageData.pageSize"
              :page-sizes="[10, 20, 50, 100]"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalSum"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="耗材目录" name="2">
          <div class="tables">
            <el-table
              border
              v-loading="dataListLoading"
              :data="tableData"
              stripe
              :row-class-name="tableRowClassName" 
              max-height="500px"
            >
              <!-- <el-table-column
                width="120"
                prop="dict_type_name"
                fixed
                label="耗材种类"
              /> -->
              <el-table-column fixed prop="name" label="耗材名称" />
              <el-table-column prop="medi_spec" label="规格" />
              <el-table-column prop="dict_first_unit_name" label="常用单位" />
              <el-table-column prop="buy_price" label="进价" />
              <el-table-column prop="sell_price" label="售价" />
              <!-- <el-table-column prop="is_early_warning" label="库存预警" /> -->
              <!-- <el-table-column prop="effective_warning" label="有效预警" /> -->
              <el-table-column prop="company" label="生产厂家" />
              <el-table-column prop="is_enable" label="是否启用">
                <template #default="scope">
                  <el-tag v-if="scope.row.is_enable == 1">是</el-tag>
                  <el-tag v-else type="danger">否</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注" />
              <el-table-column fixed="right" label="操作" width="150">
                <template #default="scope">
                  <el-popconfirm
                    title="是否确定停用?"
                    @confirm="deatilsClick(scope.row)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts stop">停</span>
                      </el-button>
                    </template>
                  </el-popconfirm>

                  <el-button
                    type="text"
                    size="small"
                    @click="modParient(scope.row)"
                  >
                    <span class="txfonts modify">改</span>
                  </el-button>
                  <el-popconfirm
                    title="是否确定删除?"
                    @confirm="delParient(scope.row)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts del">删</span>
                      </el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination-box">
            <el-pagination
              v-model:currentPage="pageData.currentPage"
              v-model:page-size="pageData.pageSize"
              :page-sizes="[10, 20, 50, 100]"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalSum"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="治疗项目录" name="3">
          <div class="tables">
            <el-table
              v-loading="dataListLoading"
              :data="tableData"
              stripe
              border
              max-height="500px"
            >
              <el-table-column
                width="120"
                prop="dict_type_name"
                fixed
                label="种类名称"
              />
              <el-table-column
                width="150"
                fixed
                prop="name"
                label="治疗项名称"
              />
              <el-table-column width="120" prop="medi_spec" label="规格" />
              <el-table-column
                width="90"
                prop="dict_first_unit_name"
                label="常用单位"
              />
              <el-table-column width="90" prop="dosage" label="单次剂量" />
              <el-table-column
                width="90"
                prop="dict_base_unit_name"
                label="计算单位"
              />
              <el-table-column
                width="90"
                prop="dict_use_way_name"
                label="途径"
              />
              <el-table-column
                width="120"
                prop="dict_frequency_name"
                label="频次"
              />
              <el-table-column width="220" label="标签">
                <template #default="scope">
                  <div class="parient-tag">
                    <el-tag v-for="item in scope.row.tag" :key="item.id">
                      {{ item.dict_tag_name }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="90" prop="buy_price" label="进价" />
              <el-table-column width="90" prop="sell_price" label="售价" />
              <el-table-column width="90" prop="is_enable" label="是否启用">
                <template #default="scope">
                  <el-tag v-if="scope.row.is_enable == 1">是</el-tag>
                  <el-tag v-else type="danger">否</el-tag>
                </template>
              </el-table-column>
              <el-table-column width="160" prop="remark" label="备注" />
              <el-table-column fixed="right" label="操作" width="150">
                <template #default="scope">
                  <el-popconfirm
                    title="是否确定停用?"
                    @confirm="deatilsClick(scope.row)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts stop">停</span>
                      </el-button>
                    </template>
                  </el-popconfirm>
                  <el-button
                    type="text"
                    size="small"
                    @click="modParient(scope.row)"
                  >
                    <span class="txfonts modify">改</span>
                  </el-button>
                  <el-popconfirm
                    title="是否确定删除?"
                    @confirm="delParient(scope.row)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts del">删</span>
                      </el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination-box">
            <el-pagination
              v-model:currentPage="pageData.currentPage"
              v-model:page-size="pageData.pageSize"
              :page-sizes="[10, 20, 50, 100]"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalSum"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 新增弹窗 -->
    <div>
      <BaseDialog
        :title="
          activeName == '1'
            ? '新增药品'
            : activeName == '2'
            ? '新增耗材'
            : '新增治疗项'
        "
        :isshow="puncture"
        @handleShow="handleShows"
        width="700px"
        height="auto"
      >
        <!-- 内容-->
        <div class="addform">
          <el-form
            :inline="true"
            :model="ruleForm"
            ref="ruleForms"
            :rules="rulesform"
            class="demo-form-inline w-144"
          >
            <!-- <el-form-item label="药品种类:" v-if="activeName == '1'">
              <el-select v-model="ruleForm.dict_type" :disabled="disabled">
                <el-option
                  v-for="item in dictList[160000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="药品名称 " v-if="activeName == '1'">
              <el-input
                v-model="ruleForm.name"
                placeholder="保存后不得修改"
                :disabled="disabled"
              />
            </el-form-item>
            <!-- <el-form-item label="耗材类别:" v-if="activeName == '2'">
              <el-select v-model="ruleForm.dict_type">
                <el-option
                  v-for="item in dictList[164000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="耗材名称 " v-if="activeName == '2'">
              <el-input
                v-model="ruleForm.name"
                placeholder="保存后不得修改"
                :disabled="disabled"
              />
            </el-form-item>
            <!-- <el-form-item label="治疗类别:" v-if="activeName == '3'">
              <el-select v-model="ruleForm.dict_type">
                <el-option
                  v-for="item in dictList[161000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="治疗名称 " v-if="activeName == '3'">
              <el-input
                v-model="ruleForm.name"
                placeholder="保存后不得修改"
                :disabled="disabled"
              />
            </el-form-item>
            <el-form-item label="规格：">
              <el-input
                :disabled="disabled"
                v-model="ruleForm.medi_spec"
                placeholder="保存后不得修改"
              />
            </el-form-item>
            <el-form-item label="常用单位：">
              <el-select v-model="ruleForm.dict_first_unit">
                <el-option
                  v-for="item in dictList[176000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生产厂家：">
              <el-input
                v-model="ruleForm.company"
                placeholder="保存后不得修改"
              ></el-input>
              <!-- <el-select
                v-model="ruleForm.dict_factory"
                :disabled="disabled"
                placeholder="保存后不得修改"
              >
                <el-option
                  v-for="item in dictList[231000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="进价：">
              <el-input v-model="ruleForm.buy_price">
                <template #append>元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="售价：">
              <el-input v-model="ruleForm.sell_price">
                <template #append>元</template>
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="库存预警：">
              <el-input v-model="ruleForm.is_early_warning">
                <template #append>件</template>
              </el-input>
            </el-form-item>
            <el-form-item label="有效预警：">
              <el-input v-model="ruleForm.effective_warning">
                <template #append>件</template>
              </el-input>
            </el-form-item> -->
            <!-- <el-form-item label="药品条码：">
              <el-input v-model="ruleForm.bar_code" />
            </el-form-item> -->
            <el-form-item label="单次剂量：" v-if="activeName != '2'">
              <el-input v-model="ruleForm.dosage">
                <template #append>
                  <div class="mt_4">
                    <el-select v-model="ruleForm.dict_base_unit">
                      <el-option
                        v-for="item in dictList[157000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="用药途径：" v-if="activeName != '2'">
              <el-select v-model="ruleForm.dict_use_way">
                <el-option
                  v-for="item in dictList[158000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用药频次：" v-if="activeName != '2'">
              <el-select style="width: 100px" v-model="ruleForm.dict_frequency">
                <el-option
                  v-for="item in dictList[159000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="最小制剂单位：" v-if="activeName == '2'">
              <el-select v-model="ruleForm.min_unit">
                <el-option v-for="item in dictList[176000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="包装数量：" v-if="activeName == '2'" >
              <el-input-number v-model="ruleForm.min_package_num" :min="1" />
            </el-form-item>
            <el-form-item label="包装单位：" v-if="activeName == '2'" prop="min_package_unit">
              <el-select v-model="ruleForm.min_package_unit">
                <el-option v-for="item in dictList[176000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-alert type="warning" show-icon :closable="false" v-if="activeName == '2'">
              <p>最小制剂单位 X 包装数量 = 包装单位</p>
            </el-alert>
            <!-- <el-form-item label="CNRDS编码：" v-if="activeName == '1'">
              <el-input v-model="ruleForm.code"></el-input>
            </el-form-item> -->
            <el-form-item label="备注：" v-if="activeName != '2'">
              <el-input v-model="ruleForm.remark" />
            </el-form-item>
            <el-form-item label="是否启用：">
              <el-switch
                v-model="ruleForm.is_enable"
                inline-prompt
                active-text="是"
                inactive-text="否"
              />
            </el-form-item>
            <el-form-item
              label="备注："
              v-if="activeName == '2'"
              style="width: 100% !important"
            >
              <el-input
                v-model="ruleForm.remark"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="请输入"
              />
            </el-form-item>
            <!-- <el-form-item
              label="药品标签："
              style="width: 100% !important"
              v-if="activeName == '1'"
            >
              <div class="parienttag">
                <div
                  :class="item.active ? 'tags' : 'tag'"
                  v-for="item in lists"
                  :key="item.id"
                  @click="tabgse(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </el-form-item> -->
            <!-- <el-form-item
              label="药品标签："
              style="width: 100% !important"
              v-if="activeName == '3'"
            >
              <div class="parient-tag">
                <el-tag
                  :class="item.active ? 'active' : ''"
                  v-for="item in doclists"
                  :key="item.id"
                  @click="tabios(item)"
                  >{{ item.name }}</el-tag
                >
              </div>
            </el-form-item> -->
          </el-form>

          <!-- 缺少标签 -->
          <!-- <div style="color: #3166ae; margin: 0 20px; display: flex" v-if="activeName !== '2'">
            <div class="faicon">
              <i class="fa fa-question-circle-o icou1"></i>
            </div>
            <div style="margin-top: 4px">缺少标签，立即查看</div>
          </div>-->
        </div>

        <el-divider />
        <div style="text-align: right">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="Confirmpuncture">确定</el-button>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, ref,getCurrentInstance} from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { gitDictList } from '@/utils/tool'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BaseDialog,
  },
  setup() {
    let { proxy } = getCurrentInstance()
    const state = reactive({
      ruleForm: {
        tag: [],
        is_enable: true,
      },
      rulesform: {
          min_package_unit: [{ required: true, trigger: 'change', message: '请选择' },]
        },
      tags: [],
      formData: {
        status: '',
      },
      dictList: {},
      tableData: [],
      listop: [],
      lists: [],
      doclist: [],
      doclists: [],
      puncture: false,
      saveLoading: false,
      ruleFormRef: null,
      isRecord: false,
      dict_type: '',
      activeName: '1',
      tableHei: '',
      disabled: false,
      dict_advice_type: '',
      dict_catalog_type: '',
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      conmons: [],
    })
    onMounted(() => {
      initData()
      tobody()
    })
    // tabs标签页点击事件
    const handleClick = (e) => {
      state.activeName = JSON.parse(JSON.stringify(e.props.name))
      state.formData.dict_type = ''
      tobody()
    }
    // 改变联机选择
    const props = {
      label: 'name',
      value: 'code',
      children: 'childrenItems',
    }
    // 弹窗关闭
    const handleShows = (val) => {
      console.log(val)
      if (!val) {
        state.ruleForm = {
          is_enable: true,
        }
        state.disabled = false

        state.dictList[332900000].forEach((item) => {
          item.active = false
        })
      }
      state.puncture = val
    }
    const tableRowClassName = ({ row, rowIndex }) => {
        console.log(row)
        if (row.min_package_unit == '') {
          return 'danger-row'
        }
        //  else if (row.check_cash == false) {
        //   return 'warning-row'
        // }
        return ''
      }
    // 新增
    const add = () => {
      state.puncture = true
    }
    // 当条数改变时
    const handleSizeChange = (e) => {
      state.pageData.pageSize = e
      tobody()
    }
    // 当页数改变时
    const handleCurrentChange = (e) => {
      state.pageData.currentPage = e
      tobody()
    }
    // 获取字典
    const initData = async () => {
      state.dictList = await gitDictList([
        '332900000',
        '210000000',
        '103000000',
        '160000000',
        '116000000',
        '176000000',
        '231000000',
        '157000000',
        '158000000',
        '159000000',
        '339600000',
        '164000000',
        '113000000',
        '161000000',
        '100000000',
        '340200000',
        '339600000',
      ])
      state.dictList[332900000].forEach((item) => {
        item.active = false
      })
      state.listop = state.dictList[332900000]
      state.lists = JSON.parse(JSON.stringify(state.dictList[332900000]))
      // 治疗项
      state.dictList[340200000].forEach((item) => {
        item.active = false
      })
      state.doclist = state.dictList[340200000]
      state.doclists = JSON.parse(JSON.stringify(state.dictList[340200000]))
    }
    // 获取列表
    const tobody = async () => {
      if (state.activeName == '1') {
        state.dict_advice_type = '163110000'
        state.dict_catalog_type = '160000000'
      } else if (state.activeName == '2') {
        state.dict_catalog_type = '164000000'
        state.dict_advice_type = ''
      } else {
        state.dict_advice_type = '163120000'
        state.dict_catalog_type = '161000000'
      }
      let data = {
        current: state.pageData.currentPage,
        size: state.pageData.pageSize,
        search_code: state.formData.searchs,
        is_enable: state.formData.status,
        dict_type: state.formData.dict_type,
        dict_advice_type: state.dict_advice_type,
        dict_catalog_type: state.dict_catalog_type,
        tag: state.tags,
      }
      let res = await service.post('/api/drug/list', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.tableData = res.data.records
          state.pageData.totalSum = res.data.total
        } else {
          state.pageData.totalSum = 0
          state.tableData = []
        }
      }
    }
    // 停用/在用选择框
    const selectchan = () => {
      tobody()
    }
    // 选择种类
    const type = () => {
      tobody()
    }
    // 新增确定
    // 新增确定
    const Confirmpuncture = async () => {
        proxy.$refs.ruleForms.validate(async (voild) => {
          if (voild) {
            if (state.activeName == '1') {
              state.ruleForm.dict_advice_type = '163110000'
              state.ruleForm.type = '160000000'
            } else if (state.activeName == '2') {
              state.ruleForm.type = '164000000'
            } else {
              state.ruleForm.dict_advice_type = '163120000'
              state.ruleForm.type = '161000000'
            }
            if (!state.ruleForm.id) {
              let res = await service.post('/api/drug/create', state.ruleForm)
              if (res.code == 0) {
                ElMessage({
                  message: '保存成功',
                  type: 'success',
                })
                tobody()
                state.puncture = false
                state.disabled = false
                state.ruleForm = {}
              }
            } else {
              let res = await service.post('/api/drug/edit', state.ruleForm)
              if (res.code == 0) {
                ElMessage({
                  message: '修改成功',
                  type: 'success',
                })
                tobody()
                state.disabled = false
                state.puncture = false
                state.ruleForm = {}
              }
            }
          } else {
            return false
          }
        })

      }
    // 表格修改
    const modParient = (row) => {
      state.ruleForm = JSON.parse(JSON.stringify(row))
      if (row.is_enable == 1) {
        state.ruleForm.is_enable = true
      } else {
        state.ruleForm.is_enable = false
      }
      console.log(state.ruleForm)
      state.disabled = true
      state.puncture = true
    }
    //删除
    const delParient = async (row) => {
      let res = await service.post('/api/drug/delete', { id: row.id })
      if (res.code == 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        tobody()
      }
    }
    //停用
    const deatilsClick = async (row) => {
      let res = await service.post('/api/drug/stop', { id: row.id })
      if (res.code == 0) {
        ElMessage({
          message: '停用成功',
          type: 'success',
        })
        tobody()
      }
    }
    // 搜索
    const search = () => {
      tobody()
    }
    // 药品标签弹窗选择
    const tabgse = (row) => {
      console.log(row)
      row.active = !row.active
      let tabList = []
      const item = state.lists
      for (let i = 0; i < item.length; i++) {
        if (item[i].active) {
          tabList.push(item[i].code)
        }
      }
      state.ruleForm.tag = tabList
    }
    // 治疗项弹窗标签选择
    const tabios = (row) => {
      row.active = !row.active
      let tabList = []
      const item = state.doclists
      for (let i = 0; i < item.length; i++) {
        if (item[i].active) {
          tabList.push(item[i].code)
        }
      }
      state.ruleForm.tag = tabList
    }
    const tagBut = (row) => {
      console.log(row)
      row.active = !row.active
      let tabList = []
      const item = state.listop
      for (let i = 0; i < item.length; i++) {
        if (item[i].active) {
          tabList.push(item[i].code)
        }
      }
      state.tags = tabList
      tobody()
    }
    const tagButs = (row) => {
      console.log(row)
      row.active = !row.active
      let tabList = []
      const item = state.doclist
      for (let i = 0; i < item.length; i++) {
        if (item[i].active) {
          tabList.push(item[i].code)
        }
      }
      state.tags = tabList
      tobody()
    }
    // 弹窗取消
    const cancel = () => {
      state.puncture = false
    }
    let handleprops = ref('')
    handleprops = {
      lazy: true,
      value: 'code',
      label: 'name',
      async lazyLoad(node, resolve) {
        const { value, level } = node
        let nodes = []
        let codes = ''
        if (level === 0) {
          codes = '339600000'
        } else {
          codes = value
        }
        let res = await gitDictList([codes])
        state.list = res[codes]
        nodes = res[codes]
        nodes.map(function (item) {
          item.leaf = level >= 2
        })
        resolve(nodes)
      },
    }
    return {
      ...toRefs(state),
      handleClick,
      search,
      initData,
      cancel,
      tobody,
      props,
      Confirmpuncture,
      tagBut,
      tabgse,
      tabios,
      handleCurrentChange,
      handleSizeChange,
      modParient,
      deatilsClick,
      delParient,
      handleShows,
      add,
      type,
      selectchan,
      handleprops,
      tagButs,
      tableRowClassName
    }
  },
}
</script>
<style lang="scss">
  .el-notification__group {
    flex: 1;
  }
  
  .notice_but {
    text-align: right;
    color: #3167a8;
    cursor: pointer;
  }
  .el-table .warning-row {
    background: #fff3e0;
  }
  .el-table .danger-row {
    background: #ffebee;
  }
  </style>
<style scoped lang="scss">
.adviceBox {
  height: 728px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.display_flex {
  display: flex;
  justify-content: space-between;
}
.search-top .w-208 {
  width: 208px;
  .searchcss {
    cursor: pointer;
  }
}
.faicon {
  font-size: 19px;
  margin-right: 6px;
  cursor: pointer;
}
.tabclis {
  height: 32px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  display: flex;
  padding: 3px;
  .tabclis_right {
    display: flex;
    padding-top: 2px;
  }
}
.addform {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    &:deep(.el-form-item__label) {
      width: 110px !important;
      text-align: right;
    }
    &:deep(.el-input) {
      width: 200px !important;
    }
    &:deep(.el-form-item) {
      width: 45% !important;
    }
    .mt_4 {
      &:deep(.el-input) {
        width: 100px !important;
      }
    }
    .parienttag {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .tag {
        margin-right: 8px;
        margin-bottom: 8px;
        color: #409eff;
        background-color: #ecf5ff;
        height: 20px;
        line-height: 20px;
        border-radius: 3px;
        text-align: center;
        font-size: 12px;
        padding: 0 10px;
        cursor: pointer;
      }
      .tags {
        margin-right: 8px;
        margin-bottom: 8px;
        background: #409eff;
        color: #ffffff;
        border-radius: 3px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        padding: 0 10px;
        cursor: pointer;
      }
    }
  }
}
.pagination-box {
  &:deep(.el-input__inner) {
    padding-right: 15px !important;
  }
}
.w-144 {
  margin-top: 20px;
}
.stop {
  background-color: #f4a939;
}
.modify {
  background-color: #3166ae;
}
.del {
  background-color: #ffecec;
  color: #ff6b6b;
  font-weight: 700;
}
.top {
  height: 35px;
  margin: 8px 0;
}
</style>

<style scoped>
.el-input-group__append .el-select >>> .el-input__inner {
  border: none !important;
}
.el-form.w-144 .el-select {
  width: 100px !important;
}
</style>
